import React from 'react';
// import styles 
// import './footer.css';

export const Footer = () => {

    return (
        <footer className='footer' style={{padding: '25px 50px'}}>
            <p>Designed with love by Tyreeck 2022!</p>
        </footer>
    )
}